import Vue from 'vue'

import './plugins/axios'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import Snotify from "vue-snotify";
import "vue-snotify/styles/material.css";
import { createPinia, PiniaVuePlugin } from 'pinia'
import router from './router'
import VueCompositionAPI from '@vue/composition-api'
import { VueMaskDirective } from 'v-mask'
import axios from "axios";

Vue.directive('mask', VueMaskDirective);

axios.defaults.baseURL = 'https://apiluupa.klint.cl/api/';
//axios.defaults.baseURL = 'https://api2luupa.klint.cl/api/';

//axios.defaults.baseURL =  'http://127.0.0.1:8000/api/';
Vue.use(PiniaVuePlugin)
const pinia = createPinia()
Vue.use(VueCompositionAPI)
Vue.use(axios)
Vue.config.productionTip = false
Vue.use(vuetify)
Vue.use(Snotify)
new Vue({
  vuetify,
  router,
  pinia,axios,
  render: h => h(App)
}).$mount('#app')


